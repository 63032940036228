/** @jsxImportSource theme-ui */
import { useCallback, useEffect, useState } from "react";
import { useSpring, animated as a, config } from "react-spring";
import { transitionTimes } from "../utils/transitions";

type Props = {
  colors: string[];
};

const ProjectsIndicator = ({ colors }: Props) => {
  const [activeSection, setActiveSection] = useState(0);

  // On mount / unMount
  useEffect(() => {
    const main: HTMLElement = document.querySelector("#main");
    if (main) {
      main.addEventListener("scroll", onScroll);

      return () => {
        main.removeEventListener("scroll", onScroll);
      };
    }
  }, []);

  // Scroll to section
  const scrollToSection = (index: number) => {
    const mainElt = document.querySelector("#main");
    mainElt?.scroll({ top: index * mainElt.clientHeight, behavior: "smooth" });
  };

  // onScroll callback
  const onScroll = useCallback((e) => {
    const h = document.querySelector("#main")?.clientHeight || 0;
    setActiveSection(Math.round(e.target.scrollTop / h));
  }, []);

  return (
    <aside
      sx={{
        display: ["none", "none", "block"],
        left: (theme) => [
          `calc(100% - ${Number(theme.space[2])}px)`,
          null,
          `calc(100% - ${Number(theme.space[3])}px)`,
        ],
        position: "fixed",
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: 10,
      }}
    >
      {colors.map((color, i) => {
        const isActive = activeSection === i;
        const { x } = useSpring({
          x: isActive ? 1 : 0,
          config: { ...config.stiff, clamp: true },
        });

        return (
          <a.button
            key={color}
            onClick={() => scrollToSection(i)}
            style={{
              transform: x
                .to({
                  range: [0, 1],
                  output: [1, 1.5],
                })
                .to((x: number) => `scaleX(${x})`),
            }}
            sx={{
              bg: "transparent",
              border: 0,
              display: "block",
              px: 0,
              py: "4px",
              transformOrigin: ["right", null, "left"],
              transition: `opacity ${transitionTimes.xxs}ms ease-out`,
              width: 2,
              willChange: "transform",
              "@media (hover: hover)": {
                "&:hover": {
                  opacity: isActive ? 1 : 0.5,
                },
              },
            }}
          >
            <span
              sx={{
                bg: isActive ? color : "textSecondary",
                borderBottomRightRadius: 2,
                borderTopRightRadius: 2,
                display: "block",
                height: "2px",
                transition: `background-color ${transitionTimes.xxs}ms ease-out`,
                width: "100%",
              }}
            />
          </a.button>
        );
      })}
    </aside>
  );
};

export default ProjectsIndicator;
