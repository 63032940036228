/** @jsxImportSource theme-ui */
import { useCallback, useEffect, useRef, useState } from "react";
import { Flex } from "theme-ui";
import { navigate } from "gatsby";
import { useSpring, animated as a, config } from "react-spring";
import ButtonLink from "./ButtonLink";
import Copy from "./Copy";
import H1 from "./H1";
import H3 from "./H3";
import ProjectIcon from "./ProjectIcon";
import Section from "./Section";
import Separator from "./Separator";
import { Project } from "../data/projects";

type Props = {
  index: number;
  project: Project;
};

const SectionProject = ({ index, project }: Props) => {
  const { client, color, excerpt, icon, name, slug } = project;

  // Ref
  const sectionRef = useRef(null);
  const [height, setSectionHeight] = useState(0);
  const [offset, setSectionOffset] = useState(0);

  // Spring creation
  const [{ y, mouse }, api] = useSpring(() => ({
    config: { ...config.stiff, clamp: true },
    y: 0,
    mouse: [0, 0],
  }));

  // On mount
  useEffect(() => {
    const main = document.querySelector("#main");
    if (main) {
      window.addEventListener("resize", onResize);
      main.addEventListener("scroll", onScroll);

      return () => {
        window.removeEventListener("resize", onResize);
        main.removeEventListener("scroll", onScroll);
        api.stop();
      };
    }
  }, []);

  const onSectionChange = () => {
    setSectionHeight(sectionRef?.current.offsetHeight);
    setSectionOffset(sectionRef?.current.offsetTop);
  };

  // On "sectionRef" load
  useEffect(onSectionChange, [sectionRef]);

  // Callbacks
  const onClick = useCallback(() => navigate(slug), []);
  const onScroll = useCallback((e) => api.start({ y: e.target.scrollTop }), []);
  const onResize = useCallback(onSectionChange, []);
  const onMouseMove = useCallback((e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.x - rect.width / 2;
    const y = e.clientY - rect.y - rect.height / 2;
    api.start({ mouse: [x, y] });
  }, []);

  // Animated elements
  const AnimatedIcon = a(ProjectIcon);

  return (
    <Section id={`section-project-${index}`} color={color} ref={sectionRef}>
      <Flex sx={{ flexDirection: ["column", null, "row"], height: "100%" }}>
        <article
          sx={{
            alignItems: ["center", null, "normal"],
            alignSelf: "center",
            display: ["flex", null, "block"],
            flex: 1,
            position: "relative",
            pt: (theme) => [Number(theme.sizes[4]) + Number(theme.space[2]) * 2, null, 0],
          }}
        >
          <div sx={{ px: [2, null, 5, null, 6] }}>
            <header sx={{ mb: 3 }}>
              <H1 sx={{ mb: 2 }}>{name}</H1>
              <Separator />
              <H3 sx={{ color: "textSecondary" }}>{client}</H3>
            </header>
            <Copy sx={{ mb: [3, null, 5] }}>{excerpt}</Copy>
            <Copy
              sx={{
                display: ["none", null, "block"],
                fontWeight: "extraBold",
                textAlign: "right",
              }}
            >
              <ButtonLink color={color} to={slug}>
                View case study
              </ButtonLink>
            </Copy>
          </div>
        </article>
        <Flex
          as="aside"
          onClick={onClick}
          onMouseMove={onMouseMove}
          role="button"
          title={`${name}: View case study`}
          sx={{
            alignItems: "center",
            flex: [null, null, 1],
            justifyContent: "center",
            minHeight: (theme) => [
              `calc(50% - ${Number(theme.sizes[4]) + Number(theme.space[2]) * 2}px)`,
              null,
              "auto",
            ],
            position: "relative",
            zIndex: 1,
          }}
        >
          <a.div
            style={{
              transform: height
                ? y
                    .to({
                      range: [offset - height, offset, offset + height],
                      output: [100, 0, 100],
                    })
                    .to((x: number) => `translateX(${x}%)`)
                : "none",
            }}
            sx={{
              bg: color,
              bottom: 0,
              left: 0,
              pointerEvents: "none",
              position: "absolute",
              right: 0,
              top: 0,
              willChange: "transform",
              zIndex: -1,
            }}
          />
          <a.div
            style={{
              opacity: height
                ? y.to({
                    range: [offset - height, offset, offset + height],
                    output: [0, 1, 0],
                  })
                : 1,
            }}
            sx={{
              perspective: (t) => t.breakpoints[0],
              px: 2,
              textAlign: "center",
              transformStyle: "preserve-3d",
              width: "100%",
              willChange: "opacity",
            }}
          >
            <AnimatedIcon
              icon={icon}
              style={{
                transform: mouse.to((x, y) => `rotateX(${-y / 50}deg) rotateY(${x / 50}deg)`),
              }}
              sx={{ height: "auto", width: ["35%", null, "50%"] }}
            />
          </a.div>
        </Flex>
      </Flex>
    </Section>
  );
};

export default SectionProject;
