/** @jsxImportSource theme-ui */

type Props = {
  children: React.ReactNode;
  className?: string;
  id?: string;
};

const H1 = ({ children, ...props }: Props) => {
  return (
    <h1
      {...props}
      sx={{
        display: "inline-block",
        fontSize: ["xl", null, "xxl", null, "xxxl"],
        fontWeight: "extraBold",
        lineHeight: "xs",
      }}
    >
      {children}
    </h1>
  );
};

export default H1;
