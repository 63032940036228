/** @jsxImportSource theme-ui */

const Separator = () => {
  return (
    <hr
      sx={{
        bg: "black",
        border: 0,
        height: "1px",
        mb: 2,
        mt: 0,
        mx: 0,
        width: 3,
      }}
    />
  );
};

export default Separator;
